<template>
	<div class="version_settings">
		<el-card>
			<el-row class="left">
				<el-col :span="24">
					<el-form :inline="true" class="demo-form-inline">
						<el-form-item>
							<el-select v-model="edition_type" placeholder="请选择APP">
						    <el-option v-for="(item,index) in options_type" :key="index"
						      :label="item" :value="index">
						    </el-option>
						  </el-select>
						</el-form-item>
						<el-form-item>
							<el-select v-model="edition_os" placeholder="请选择类型">
						    <el-option v-for="(item,index) in options_os" :key="index" :label="item" :value="index">
						    </el-option>
						  </el-select>
						</el-form-item>
						<el-form-item>
							<el-input v-model="edition_version" placeholder="请输入版本名称" clearable></el-input>
						</el-form-item>
						<el-form-item>
							<el-button type="primary" @click="search" icon="el-icon-search">搜索</el-button>
							<el-button type="default" @click="reset" icon="el-icon-refresh">重置</el-button>
						</el-form-item>
					</el-form>
				</el-col>
			</el-row>
			<el-row class="left">
				<el-col :span="24">
					<el-form :inline="true" class="demo-form-inline">
						<el-form-item>
							<el-button type="primary" size="mini" icon="el-icon-plus" @click="add">添加</el-button>
						</el-form-item>
						<el-form-item>
							<el-button type="success" size="mini" icon="el-icon-edit" @click="updatePrivacy(1)">隐私政策</el-button>
						</el-form-item>
						<el-form-item>
							<el-button type="success" size="mini" icon="el-icon-edit" @click="updatePrivacy(2)">用户协议</el-button>
						</el-form-item>
					</el-form>
				</el-col>
			</el-row>
			<el-table border :data="list" style="width: 100%" :header-cell-style="{ background: '#eef1f6', color: '#606266' }">
				<el-table-column label="ID" prop="edition_id" width="60"></el-table-column>
				<el-table-column :show-overflow-tooltip="true" label="APP" prop="edition_type_name"></el-table-column>
				<el-table-column :show-overflow-tooltip="true" label="系统" prop="edition_os_name" width="100"></el-table-column>
				<el-table-column label="包类型">
					<template slot-scope="scope">
						<div>
							<el-tag type="primary" v-if="scope.row.package_type==1">apk</el-tag>
							<el-tag type="success" v-if="scope.row.package_type==2">wgt</el-tag>
						</div>
					</template>
				</el-table-column>
				<el-table-column label="版本号" prop="edition_version" ></el-table-column>
				<el-table-column label="版本序号" prop="edition_code"></el-table-column>
				<el-table-column :show-overflow-tooltip="true" label="更新内容" prop="edition_content"></el-table-column>
				<el-table-column label="更新时间" prop="edition_time" width="150"></el-table-column>
				<el-table-column label="操作" fixed="right" width="150">
					<template slot-scope="scope">
						<div>
							<el-button type="primary" size="mini" @click="update(scope.row)">修改</el-button>
							<el-button type="danger" size="mini" @click="remove(scope.row)">删除</el-button>
						</div>
					</template>
				</el-table-column>
			</el-table>
			<Pagination :queryInfo="queryInfo" :total="total" @handleSizeChange="handleSizeChange" @handleCurrentChange="handleCurrentChange"></Pagination>
		</el-card>
		<!-- 添加/更新 -->
		<el-dialog :title="title" width="60%" top="5vh" :visible.sync="addVisible" v-loading="loading">
			<el-form ref="addRef" :model="addForm" :rules="addRules" label-width="100px" class="left">
				<el-row>
					<el-col :span="24">
						<el-form-item label="版本包:">
							<OssUpload v-model="addForm.edition_url" :limit="1" :fileSize="300" :fileType="['apk','wgt']" ref="fileUploadRef"></OssUpload>
							<!-- <el-upload
							  class="upload-demo"
							  ref="upload"
							  accept=".apk"
							  action="https://jsonplaceholder.typicode.com/posts/"
							  :on-change="handleChange"
							  :file-list="fileList"
							  :auto-upload="false">
							  <el-button slot="trigger" size="small" type="primary">选取文件</el-button>
							  <div slot="tip" class="el-upload__tip">安卓上传apk文件，苹果上传wgt文件</div>
							</el-upload> -->
						</el-form-item>
					</el-col>
					<el-col :span="8">
						<el-form-item label="APP:" prop="edition_type">
							<el-select v-model="addForm.edition_type" placeholder="请选择APP">
								<el-option v-for="(item,index) in options_type" :key="index"
						      :label="item" :value="index">
						    </el-option>
						 	</el-select>
						</el-form-item>
					</el-col>
					<el-col :span="8">
						<el-form-item label="系统:" prop="edition_os">
							<el-select v-model="addForm.edition_os" placeholder="请选择类型">
								<el-option v-for="(item,index) in options_os" :key="index"
						      :label="item" :value="index">
						    </el-option>
							</el-select>
						</el-form-item>
					</el-col>
					<el-col :span="8">
						<el-form-item label="包类型:" prop="package_type">
							<el-select v-model="addForm.package_type" placeholder="请选择类型">
								<el-option v-for="(item,index) in package_list" :key="index" :label="item.name" :value="item.value">
						    </el-option>
							</el-select>
						</el-form-item>
					</el-col>
					<el-col :span="12">
						<el-form-item label="版本号:" prop="edition_version">
							<el-input v-model="addForm.edition_version" maxlength="10" placeholder="请输入版本号,如1.0.0"></el-input>
						</el-form-item>
					</el-col>
					<el-col :span="12">
						<el-form-item label="版本序号:" prop="edition_code">
							<el-input v-model="addForm.edition_code" maxlength="10" placeholder="请输入版本序号,如1"></el-input>
						</el-form-item>
					</el-col>
					<el-col :span="24">
						<el-form-item label="更新链接:" prop="edition_url">
							<el-input v-model="addForm.edition_url" placeholder="请输入更新链接"></el-input>
						</el-form-item>
					</el-col>
					<el-col :span="24">
						<el-form-item label="更新内容:" prop="edition_content">
							<el-input type="textarea" :rows="4" v-model="addForm.edition_content" placeholder="请输入更新内容"></el-input>
						</el-form-item>
					</el-col>
					<el-col :span="24">
						<el-form-item label="更新图文:" prop="edition_desc">
							<el-upload class="avatar-uploader" action="https://jsonplaceholder.typicode.com/posts/" accept="image/*" :show-file-list="false" :before-upload="beforeUpload" :http-request="getUploadUrl" style="height: 0px;"></el-upload>
							<quill-editor v-model="addForm.edition_desc" :options="editorOption" ref="QuillEditor"></quill-editor>
						</el-form-item>
					</el-col>
					<el-col :span="24">
						<el-form-item label="是否强制更新:" prop="is_force">
							<el-switch v-model="is_force" @change="forceChange"></el-switch>
						</el-form-item>
					</el-col>
				</el-row>
			</el-form>
			<div>
				<el-button type="default" @click="addClose">取消</el-button>
				<el-button type="primary" @click="addSub">保存</el-button>
			</div>
		</el-dialog>
		
		<!-- 添加/更新 -->
		<el-dialog :title="title" width="70%" top="5vh" :visible.sync="privacyVisible" v-loading="loading">
			<el-form ref="addRef" :model="privacyForm" label-width="0px" class="left">
				<el-row>
					<el-col :span="24">
						<el-form-item label="" prop="privacy_content">
							<el-upload class="avatar-uploader" action="https://jsonplaceholder.typicode.com/posts/" accept="image/*" 
								:show-file-list="false" :before-upload="beforeUpload" :http-request="getUploadUrl" style="height: 0px;"></el-upload>
							<quill-editor v-model="privacyForm.privacy_content" :options="editorOption" ref="QuillEditor"></quill-editor>
						</el-form-item>
					</el-col>
				</el-row>
			</el-form>
			<div>
				<el-button type="default" @click="privacyClose">取消</el-button>
				<el-button type="primary" @click="privacySubmit">保存</el-button>
			</div>
		</el-dialog>
		
	</div>
</template>

<script>
	let _this;
	let timer = null;
	import axios from 'axios'
	import {beforeUrl} from "@/api/request.js";
	import Pagination from '@/components/Pagination/Pagination.vue'
	import OssUpload from '@/components/Upload/OssUpload.vue'
	const toolbarOptions = [
		['bold', 'italic', 'underline', 'strike'], // 加粗，斜体，下划线，删除线
		['blockquote', 'code-block'], //引用，代码块
		[{
			'header': 1
		}, {
			'header': 2
		}], // 几级标题
		[{
			'list': 'ordered'
		}, {
			'list': 'bullet'
		}], // 有序列表，无序列表
		[{
			'script': 'sub'
		}, {
			'script': 'super'
		}], // 下角标，上角标
		[{
			'indent': '-1'
		}, {
			'indent': '+1'
		}], // 缩进
		[{
			'direction': 'rtl'
		}], // 文字输入方向
		[{
			'size': ['small', false, 'large', 'huge']
		}], // 字体大小
		[{
			'header': [1, 2, 3, 4, 5, 6, false]
		}], // 标题
		[{
			'color': []
		}, {
			'background': []
		}], // 颜色选择
		[{
			'font': ['SimSun', 'SimHei', 'Microsoft-YaHei', 'KaiTi', 'FangSong', 'Arial']
		}], // 字体
		[{
			'align': []
		}], // 居中
		['clean'], // 清除样式,
		['link', 'image'], // 上传图片、上传视频
	]
	export default {
		components: {
			Pagination,OssUpload
		},
		data() {
			return {
				queryInfo: {
					pagenum: 1,
					pagesize: 10,
				},
				title: '添加版本',
				total: 0,
				edition_type: '',
				edition_os: '',
				options_type: [],
				options_os: [],
				package_list: [
					{name:'apk',value:1},
					{name:'wgt',value:2}
				],
				edition_version: '',
				list: [],
				fileData: {},
				fileList: [],
				addVisible: false,
				loading: false,
				addForm: {
					edition_id: '',
					edition_type: '1',
					edition_os: '1',
					edition_version: '',
					edition_code: '',
					edition_content: '',
					edition_url: '',
					edition_desc: '',
					is_force: 0,
					package_type: 0
				},
				is_force: false,
				addRules: {
					edition_type: [{
						required: true,
						message: '请选择app',
						trigger: 'blur'
					}],
					edition_os: [{
						required: true,
						message: '请选择类型',
						trigger: 'blur'
					}],
					edition_version: [{
						required: true,
						message: '版本名称不能为空',
						trigger: 'blur'
					}],
					edition_code: [{
						required: true,
						message: '版本号不能为空',
						trigger: 'blur'
					}],
				},
				editorOption: {
					placeholder: '请在这里输入',
					theme: 'snow', //主题 snow/bubble
					modules: {
						history: {
							delay: 1000,
							maxStack: 50,
							userOnly: false
						},
						toolbar: {
							container: toolbarOptions,
							handlers: {
								image: function(value) {
									if(value) {
										// 调用element的图片上传组件
										document.querySelector('.avatar-uploader input').click()
									} else {
										this.quill.format('image', false)
									}
								}
							}
						}
					}
				},
				
				privacyVisible: false,
				privacyForm: {
					privacy: 0,
					privacy_content: ''
				},
			}
		},
		created() {
			_this = this;
			if(!this.$store.state.userInfo) {
				// 防止未登录
				this.$router.push({
					path: '/login'
				})
			} else {
				this.initData();
				this.getEditionList();
			}
		},
		methods: {
			beforeUpload (file) { },
			async getUploadUrl(fileData){
				var url = 'index/imgUpload';
				var formData = new FormData();
				//如果存在文件
				if(fileData.file) {
					formData.append("file", fileData.file, fileData.file.name);
					if(timer != null) {clearTimeout(timer);}
					timer = setTimeout(() => {
						axios({
							url: url,
							headers: {
								"Content-Type": "multipart/form-data",
							},
							method: "post",
							data: formData,
						}).then(res => {
							console.log(res);
							// 获取富文本组件实例
					      	let quill = this.$refs.QuillEditor.quill
					      	// 如果上传成功
					      	if (res.status==200) {
					        	// 获取光标所在位置
			        			let length = quill.getSelection().index;
						        // 插入图片，res为服务器返回的图片链接地址
						        quill.insertEmbed(length, 'image', res.data)
						        // 调整光标到最后
						        quill.setSelection(length + 1)
					      	}
						}).catch(error => {
							_this.$message.error('网络错误');
						})
					}, 300);
				}
			},
			initData() {
				var url = 'edition/init_data';
				let params = {};
				this.fd_post(url, params).then((res) => {
					_this.options_type = res.options_type;
					_this.options_os = res.options_os;
				}).catch((err) => {
					_this.$message.error('网络错误');
				});
			},
			getEditionList() {
				var url = 'edition/edition_list';
				let params = {
					edition_type: this.edition_type,
					edition_os: this.edition_os,
					edition_version: this.edition_version,
					size: this.queryInfo.pagesize,
					page: this.queryInfo.pagenum,
				};
				this.fd_post(url, params).then((res) => {
					if(res.status) {
						_this.total = res.total;
						_this.list = res.list;
					} else {
						_this.$message.error(res.msg);
					}
				}).catch((err) => {
					_this.$message.error('网络错误');
				});
			},
			//查询
			search() {
				this.getEditionList();
			},
			// 重置
			reset() {
				this.edition_type = "";
				this.edition_os = "";
				this.edition_version = "";
				this.getEditionList();
			},
			//页数改变
			handleSizeChange(newSize) {
				this.queryInfo.pagenum = 1
				this.queryInfo.pagesize = newSize
				this.getEditionList();
			},
			//页码改变
			handleCurrentChange(newPage) {
				this.queryInfo.pagenum = newPage
				this.getEditionList();
			},
			//上传
			handleChange(file, fileList){
				this.fileData = file;
				this.fileList = fileList.slice(-1);
			},
			forceChange(e){
				console.log(e)
				this.addForm.is_force = this.is_force ? 1 : 0
			},
			//添加弹窗打开
			add() {
				this.title = '添加版本';
				this.addForm = {
					edition_id: '',
					edition_type: '1',
					edition_os: '1',
					edition_version: '',
					edition_code: '',
					edition_content: '',
					edition_url: '',
					edition_desc: '',
					is_force: 0
				};
				this.addVisible = true;
			},
			update(item){
				this.title = '编辑版本';
				this.addForm = item;
				this.is_force = item.is_force > 0
				this.addVisible = true;
			},
			//关闭添加弹窗
			addClose() {
				this.$refs.addRef.resetFields()
				this.addForm.role_desc = ''
				this.addVisible = false
			},
			async uploadApk(){
			  await this.$refs.fileUploadRef.handleUploadSubmit().then(async (res)=>{
			    if (res.error) {
			      this.$message.error(res.message);
			    } else {
						
						console.log("【页面接受的图片信息】",res)
						
			      var imgList = res.imgList
			
			      this.addForm.edition_url = imgList.length > 0 ? imgList[0].url : "";
			
			      console.log(this.addForm.edition_url);
			
			      this.doSubmit()
			
			    }
			  }).catch(()=>{
			   
			  });
			},
			//确认添加
			addSub() {
				this.$refs.addRef.validate(value => {
					if(!value) return;
					if (this.addForm.edition_url) {
						this.doSubmit()
					} else {
						this.uploadApk()
					}
				})
			},
			async doSubmit() {
				if(timer != null) {
					clearTimeout(timer);
				}
				this.loading = true;
				var url = 'edition/addOrUpdate';
				var formData = new FormData();
				formData.append("edition_id", this.addForm.edition_id);
				formData.append("edition_type", this.addForm.edition_type);
				formData.append("edition_os", this.addForm.edition_os);
				formData.append("edition_version", this.addForm.edition_version);
				formData.append("edition_code", this.addForm.edition_code);
				formData.append("edition_content", this.addForm.edition_content);
				formData.append("edition_url", this.addForm.edition_url);
				formData.append("edition_desc", this.addForm.edition_desc);
				formData.append("is_force", this.addForm.is_force);
				formData.append("package_type", this.addForm.package_type);
	
				timer = setTimeout(() => {
					axios({
						url: url,
						headers: {
							"Content-Type": "multipart/form-data",
						},
						method: "post",
						data: formData,
					}).then(res => {
						_this.loading = false;
						_this.addVisible = false;
						if(res.data.status) {
							_this.$message.success('操作成功');
							_this.getEditionList();
						} else {
							_this.$message.error(res.data.msg);
						}
					}).catch(error => {
						_this.loading = false;
						_this.addVisible = false;
						_this.$message.error('网络错误');
					})
				}, 300);
			},
			//删除
			remove(item) {
				this.$confirm('你确定要删除吗,此操作将永久删除该条数据?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					if(timer != null){clearTimeout(timer);}
					timer = setTimeout(() => {
						var url = 'edition/delete_edition';
				    	let params = {
				    		edition_id: item.edition_id
				    	};
						_this.fd_post(url, params).then((res) => {
				    		if(res.status){
				    			_this.$message.success("操作成功");
				    			_this.getEditionList();
				    		}else{
				    			_this.$message.error(res.msg);
				    		}
				        }).catch((err) => {
				            _this.$message.error('网络错误');
				        });
					},300);
				}).catch(() => {
					this.$message.info('已取消删除');
				});
			},
			updatePrivacy(privacy) {
				this.loading = true;
				this.title = privacy==1?'隐私政策':'用户协议';
				var url = 'edition/get_privacy';
				let params = {
					privacy: privacy
				};
				this.fd_post(url, params).then((res) => {
					this.loading = false;
					if(res.status){
						this.privacyForm = {
							privacy: privacy,
							privacy_content: res.info.privacy_content,
						};
						this.privacyVisible = true;
					}
				}).catch((err) => {
						this.$message.error('网络错误');
				});
			},
			privacyClose() {
				this.$refs.addRef.resetFields()
				this.privacyForm.privacy_content = ''
				this.privacyVisible = false
			},
			privacySubmit() {
				this.loading = true;
				var url = 'edition/updatePrivacy';
				this.fd_post(url, this.privacyForm).then((res) => {
					this.loading = false;
					if(res.status){
						this.privacyVisible = false;
						this.$message.info('更新成功');
					}
				}).catch((err) => {
						this.$message.error('网络错误');
				});
			},
		},
	}
</script>

<style lang="scss" scoped>
	@import './Version_settings.scss';
</style>